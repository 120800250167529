import axios from 'axios'
import config from 'utilis/config'

const API_URL = 'https://cj3sr5wgvc.execute-api.ap-south-1.amazonaws.com/dev/consumer';


export const submitFormSubmission = async (body) => {
    try{
        const res = await axios.post(API_URL, body)
        console.log(res);
        return res.data

    } catch(err){
        console.log(err)
        throw err;
    }
}


