import Header from "./components/Header";
import Hero from "./components/Hero";
import About from "./components/About";
import Eligibility from "./components/Eligibility";
import Timeline from "./components/Timeline";
import ApplicationForm from "./components/ApplicationForm";
import Contact from "./components/Contact";

const Landing = () => {

    return(
        <div>
            <Header />
            <Hero />
            <About />
            <Eligibility />
            <Timeline />
            <ApplicationForm />
            <Contact />
        </div>
    )
}

export default Landing;