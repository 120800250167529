import { SimpleModal } from 'component/Models/Modal'
import React, {useState, useEffect, useContext} from 'react'

const ConnectWallet = ({show, setShow, status}) => {

    const [count, setCount] = useState(5)

    useEffect(() => {
        if(status === 'complete'){
            setInterval(() => setCount(prev => prev-1), 1000) 
        }
    }, [status])

    return (
        <SimpleModal
            show = {show}
            setShow = {setShow}
        >
            <div class='py-6'>
                {status === 'metamask' && <div class='px-6 text-center mb-6 mt-5'>
                    <img src = "/assets/metamask.png" class='img-fluid' style = {{height: '150px'}}/>
                    <div class='h2 mb-1'>Waiting for confirmation from Metamask</div>
                    <span class='spinner-border spinner-border-lg mt-5'></span>
                </div>}
                {status === 'data-submission' && <div class='px-6 text-center mb-4 mt-5'>
                    <img src = "/assets/development.svg" class='img-fluid mb-3 mr-2' style = {{height: '200px'}}/>
                    <div class='h2 mb-1'>Metamask verified!</div>
                    <span class='spinner-border spinner-border-lg mt-5'></span>
                </div>}
                {status === 'complete' && <div class='px-6 text-center my-4'>
                    <img src = "/assets/email-verification.svg" class='img-fluid mb-3 mr-6 pr-6' style = {{height: '200px'}}/>
                    <div class='h2 mb-1'>Form submitted succesfully</div>
                </div>}
            </div>
            
        </SimpleModal>
    )
}

export default ConnectWallet