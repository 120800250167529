import React, { useEffect, useState } from "react";
import questionsList from "./utilis/";
import Question from "./components/Question";
import AuthLayout from "component/Layout/Auth";
import Text from "component/Text";
import toast from "react-hot-toast";
import ConnectWallet from "./components/ConnectWallet";
import { draftMessageForSignature } from "./utilis";
import { submitFormSubmission } from "services";
import { Redirect } from "react-router-dom";
import lodash from "lodash";

const questions = lodash.cloneDeep(questionsList);

const Form = () => {
    
    const [details, setDetails] = useState(questions);
    const [section, setSection] = useState(0);
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState("");
    const [errors, setErrors] = useState(errorStateMaker());
    const [withoutWallet, setWithoutWallet] = useState(false);


    useEffect(() => {
        if(Object.values(errors.flat().map(error => error.error)).includes(true)) {
            setErrors(errorStateMaker());
        }
    }, [details])


    
    const isMetaMaskInstalled = () => {
        const { ethereum } = window;
        return Boolean(ethereum && ethereum.isMetaMask);
    };


    const redirect = () => {
        toast.success("Your submission has been succesfully recieved!");
        setTimeout(() => {
            window.location.href = `${process.env.REACT_APP_REDIRECT_URL}`;
        }, 2000);
    }


    const validateSection = (section) => {
        
        const errors_ = [...errors];
        const item = details[section];
        const { questions } = item;

        let noError = true;
        for (let i = 0; i < questions.length; i++) {
            const question_ = questions[i];
            const inputVals = question_.value;
            const isError = errors_[section][i].errorLogic(inputVals);
            if (isError) {
                errors_[section][i].error = true;
                noError = false;
            } else {
                errors_[section][i].error = false;
            }
        }

        setErrors(errors_);
        return noError;
    };


    const nextPage = async() => {
        if(!validateSection(section)) {
            return;
        }
        if(section === 3) {
            if (withoutWallet) {
                await submitWithoutWallet();
                return;
            } 
            await connectWallet();
            return;
        } 
        setSection(section + 1);
    };

    const connectWallet = async () => {
        try {
            let address = await connectMetamaskWallet();
            if (!address) {
                return;
            }
            setLoading(false);
            redirect()
        } catch (err) {
            console.log(err);
            toast.error("Something went wrong, please try again");
            setLoading(false);
            setShow(false);
        }
    };


    const submitWithoutWallet = async () => {
        try{
            setLoading(true)
            setStatus("data-submission");
            await submitFormSubmission({
                details
            });
            setStatus("complete");
            setLoading(false)
            redirect()
        } catch(err){
            console.log(err)
            toast.error("Something went wrong, please try again");
            setLoading(false)
        }
    };

    const connectMetamaskWallet = async () => {
        if (!isMetaMaskInstalled()) {
            toast.error("Please install MetaMask wallet!");
            return;
        }
        setLoading("metamask");
        setStatus("metamask");
        setShow(true);
        try {
            const accounts = await window.ethereum.request({
                method: "eth_requestAccounts"
            });
            if (accounts && accounts.length > 0) {
                let message = draftMessageForSignature(details);
                const request = {
                    method: "personal_sign",
                    params: [message, accounts[0]]
                };
                const signature = await window.ethereum.request(request);
                setStatus("data-submission");
                await submitFormSubmission({
                    address: accounts[0],
                    signature,
                    details
                });
                setStatus("complete");
                return accounts[0];
            } else {
                throw new Error("");
            }
        } catch (err) {
            throw err;
        }
    };

    const handleInputChange = (value, section, questionIdx) => {
        const details_ = [...details];
        const questions_ = [...details[section].questions];
        details_[section].questions[questionIdx] = {
            ...questions_[questionIdx],
            value: value
        };
        setDetails(details_);
    };


    useEffect(() => {
        //edit body className when enter this component
        document.body.classList.add("d-flex")
        document.body.classList.add("min-h-100");
        document.body.classList.add("align-items-center")
        return () => {
            document.body.classList.remove("align-items-center");
            document.body.classList.remove("d-flex");
            document.body.classList.remove("min-h-100");
        }
    }, [])

    return (
        <AuthLayout>
            <form className="mt-5">
                <h2 class='mb-1'>
                    {details.find((i) => i.index === section).section}
                </h2>
                <small className="d-block mb-4">All fields are mandatory!</small>
                {details
                    .find((i) => i.index === section)
                    .questions.map((question, index) => {
                        return (
                            <Question
                                {...question}
                                error={
                                    errors[section][index].error
                                        ? errors[section][index].errorMessage
                                        : null
                                }
                                handleInputChange={handleInputChange}
                                section={section}
                                questionIdx={index}
                            />
                        );
                    })}
                {section === 3 && (
                    <div
                        class="form-check form-switch"
                        onClick={() => {
                            setWithoutWallet((prev) => !prev);
                        }}
                    >
                        <input
                            class="form-check-input"
                            type="checkbox"
                            checked={!withoutWallet}
                            readOnly
                        />
                        <label class="form-check-label">
                            Submit with metamask (recommended)
                        </label>
                    </div>
                )}
                <div class="border-top mt-3 pt-3 d-flex justify-content-between">
                    <div>
                        {section !== 0 && (
                            <div
                                className="btn btn-soft-primary"
                                onClick={() => setSection(section - 1)}
                            >
                                Previous
                            </div>
                        )}
                    </div>
                    <div class={section === 0 ? "w-100" : "w-50"}>
                        <div
                            className="btn btn-primary w-100"
                            onClick={() => nextPage()}
                        >
                            {loading && <span class='spinner-border spinner-border-sm mr-2'></span>}
                            {section === 3 ? (withoutWallet ? "Submit" : "Wallet Submit") : "Next"}
                        </div>
                    </div>
                </div>
            </form>
            <ConnectWallet show={show} setShow={setShow} status={status} />
        </AuthLayout>
    );
};



export default Form;


const errorStateMaker = () => {
    const errorState = [];
    for (let i = 0; i < questions.length; i++) {
        const sectionErrors = [];
        const sectionQuestions = questions[i]?.questions;
        for (let j = 0; j < sectionQuestions.length; j++) {
            const question = sectionQuestions[j];

            sectionErrors.push({
                error: question.error,
                errorMessage: question.errorMessage,
                errorLogic: question.errorLogic
            });
        }

        errorState.push(sectionErrors);
    }

    return errorState;
};
