import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
// import * as Sentry from "@sentry/react";
// import { BrowserTracing } from "@sentry/tracing";

// Sentry.init({
//   dsn: "https://f40e7bbb4d594a80afcce80c4fbda049@o1327827.ingest.sentry.io/6590642",
//   integrations: [new BrowserTracing()],
//   tracesSampleRate: 1.0,
// });


ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('main-content')
);