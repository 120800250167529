import React, { useEffect, useState } from 'react';
import {initAnalysis} from 'utilis/logEvents'
import {Toaster} from 'react-hot-toast'
import { Redirect, Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import Form from 'pages/Form';
import Landing from 'pages/Landing';
import './assets/css/home.css'

const App = (props) => {
    
    let [isInitialized, setIsInitialized] = useState(false)

    useEffect(() => {
        if(!window.location.host.includes('localhost') && !window.location.host.includes('staging')){
            initAnalysis()
            setIsInitialized(true)
        } else {
            setIsInitialized(true) 
        }
    }, [])

    if(!isInitialized) return <></>
    return (
        <>
            <Toaster />
            <Router>
                <Switch>
                    <Route path="/form" exact component={Form} />
                    <Route path="/" exact component={Landing} />
                    <Redirect from = "*" to = "/" />
                </Switch>
            </Router>
        </>
    );
}

export default App;

