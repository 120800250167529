import moment from "moment";
import validation from "../../../utilis/validation";

let questions = [
    {
        section: "Basic Details",
        index: 0,
        questions: [
            {
                label: "Name of the Applicant",
                key: "name",
                title: "name",
                type: "TEXT",
                value: "",
                error: false,
                errorMessage: "Mandatory field*",
                errorLogic: (input) => {
                    if(validation.isEmpty(input)){
                        return true
                    }
                    return false;
                }
            },
            {
                label: "Nature of the applicant",
                key: "nature",
                title: "nature",
                options: ["Individual", "Group", "Registered Organization"],
                type: "OPTIONS",
                value: "Individual",
                error: false,
                errorMessage: "Mandatory field*",
                errorLogic: (input) => {
                    if(validation.isEmpty(input)){
                        return true
                    }
                    return false;
                }
            },
            {
                label: "Date of incorporation or date of commencement of the business",
                key: "date_of_incorporation",
                title: "date_of_incorporation",
                type: "DATE",
                value: "",
                error: false,
                errorMessage: "Mandatory field*",
                errorLogic: (input) => {
                    if(!input) return true;
                    let date_ = moment(input, 'yyyy-mm-dd')
                    if(!date_.isValid()){
                        console.log("Invalid date")
                        return true
                    }
                    if(date_.year() < 1996){
                        console.log(date_.year())
                        return true
                    }
                    return false;
                }
            },
            {
                label: "Working Style",
                key: "working_style",
                title: "working_style",
                options: ["WFH", "WFO", "Hybrid"],
                type: "OPTIONS",
                value: "WFH",
                error: false,
                errorMessage: "Mandatory field*",
                errorLogic: (input) => {
                    return false;
                }
            },
            {
                label: "Corporate identification number or any registration number of similar nature",
                key: "cin",
                title: "cin",
                type: "TEXT",
                value: "",
                error: false,
                errorMessage: "Mandatory field*",
                errorLogic: (input) => {
                    if(validation.isEmpty(input)){
                        return true
                    }
                    return false;
                }
            },
            {
                label: "Organization Size (number of employees)",
                key: "size",
                title: "size",
                type: "NUMBER",
                value: "",
                error: false,
                errorMessage: "Mandatory field*",
                errorLogic: (input) => {
                    if(validation.isEmpty(input)){
                        return true
                    }
                    return false;
                }
            },
            {
                label: "Problem Statement being tackled by the applicant",
                key: "problem_statement",
                title: "problem_statement",
                type: "TEXTAREA",
                value: "",
                error: false,
                errorMessage: "Mandatory field*",
                errorLogic: (input) => {
                    if(validation.isEmpty(input)){
                        return true
                    }
                    return false;
                }
            }
        ]
    },
    {
        section: "Details of the authorized representative",
        index: 1,
        questions: [
            {
                label: "Name",
                key: "name_of_representative",
                title: "name_of_representative",
                type: "TEXT",
                value: "",
                error: false,
                errorMessage: "Mandatory field*",
                errorLogic: (input) => {
                    if(validation.isEmpty(input)){
                        return true
                    }
                    return false;
                }
            },
            {
                label: "Designation",
                key: "designation",
                title: "designation",
                type: "TEXT",
                value: "",
                error: false,
                errorMessage: "Mandatory field*",
                errorLogic: (input) => {
                    if(validation.isEmpty(input)){
                        return true
                    }
                    return false;
                }
            },
            {
                label: "Email Address",
                key: "email",
                title: "email",
                type: "EMAIL",
                value: "",
                error: false,
                errorMessage: "invalid email",
                errorLogic: (input) => {
                    return !input || !validation.isEmail(input);
                }
            },
            {
                label: "Contact Number",
                key: "contact_number",
                title: "contact_number",
                type: "PHONE",
                value: "",
                error: false,
                errorMessage: "invalid phone",
                errorLogic: (input) => {
                    return !input || !validation.isPhone(input);
                }
            }
        ]
    },
    {
        section: "Provide a detailed description of the solution framework and the activities undertaken including but not limited to",
        index: 2,
        questions: [
            {
                label: "The objective of the proposed solution or the statement of purpose",
                key: "objective",
                title: "objective",
                type: "TEXTAREA",
                value: "",
                error: false,
                errorMessage: "Mandatory field*",
                errorLogic: (input) => {
                    if(validation.isEmpty(input)){
                        return true
                    }
                    return false;
                }
            },
            {
                label: "Key benefits to the users and markets",
                label: "Title benefits to the users and markets",
                key: "key_benefits",
                key: "key_benefits",
                title: "title",
                type: "TEXTAREA",
                value: "",
                error: false,
                errorMessage: "Mandatory field*",
                errorLogic: (input) => {
                    if(validation.isEmpty(input)){
                        return true
                    }
                    return false;
                }
            },
            {
                label: "Business Model, including asset deployment and sources of revenue",
                key: "business_model",
                title: "business_model",
                type: "TEXTAREA",
                value: "",
                error: false,
                errorMessage: "Mandatory field*",
                errorLogic: (input) => {
                    if(validation.isEmpty(input)){
                        return true
                    }
                    return false;
                }
            },
            {
                label: "Target users",
                key: "target_users",
                title: "target_users",
                type: "TEXTAREA",
                value: "",
                error: false,
                errorMessage: "Mandatory field*",
                errorLogic: (input) => {
                    if(validation.isEmpty(input)){
                        return true
                    }
                    return false;
                }
            },
            {
                label: "Compliance obligations",
                key: "compliance_obligations",
                title: "compliance_obligations",
                type: "TEXTAREA",
                value: "",
                error: false,
                errorMessage: "Mandatory field*",
                errorLogic: (input) => {
                    if(validation.isEmpty(input)){
                        return true
                    }
                    return false;
                }
            },
            {
                label: "Technical architecture",
                key: "technical_architecture",
                title: "technical_architecture",
                type: "TEXTAREA",
                value: "",
                error: false,
                errorMessage: "Mandatory field*",
                errorLogic: (input) => {
                    if(validation.isEmpty(input)){
                        return true
                    }
                    return false;
                }
            },
            {
                label: "Usage of Artificial Intelligence and Machine Learning, if any",
                key: "ai",
                title: "ai",
                type: "TEXTAREA",
                value: "",
                error: false,
                errorMessage: "Mandatory field*",
                errorLogic: (input) => {
                    if(validation.isEmpty(input)){
                        return true
                    }
                    return false;
                }
            },
            {
                label: "Cyber resilience: VAPT results, if any",
                key: "vapt",
                title: "vapt",
                type: "TEXTAREA",
                value: "",
                error: false,
                errorMessage: "Mandatory field*",
                errorLogic: (input) => {
                    if(validation.isEmpty(input)){
                        return true
                    }
                    return false;
                }
            },
            {
                label: "Certification from Common Criteria Recognition Arrangement (CCRA), if any",
                key: "ccra",
                title: "ccra",
                type: "TEXTAREA",
                value: "",
                error: false,
                errorMessage: "Mandatory field*",
                errorLogic: (input) => {
                    if(validation.isEmpty(input)){
                        return true
                    }
                    return false;
                }
            },
            {
                label: "Business Continuity Plan, if any",
                key: "business_continuity_plan",
                title: "business_continuity_plan",
                type: "TEXTAREA",
                value: "",
                error: false,
                errorMessage: "Mandatory field*",
                errorLogic: (input) => {
                    if(validation.isEmpty(input)){
                        return true
                    }
                    return false;
                }
            },
            {
                label: "Any other certifications, if any",
                key: "any_certs",
                title: "any_certs",
                type: "TEXTAREA",
                value: "",
                error: false,
                errorMessage: "Mandatory field*",
                errorLogic: (input) => {
                    if(validation.isEmpty(input)){
                        return true
                    }
                    return false;
                }
            }
        ]
    },
    {
        section: "Just few more details",
        index: 3,
        questions: [
            {
                label: "Does the applicant have a deployable solution ready?",
                key: "deployable_solution",
                title: "deployable_solution",
                type: "TEXTAREA",
                value: "",
                error: false,
                errorMessage: "Mandatory field*",
                errorLogic: (input) => {
                    if(validation.isEmpty(input)){
                        return true
                    }
                    return false;
                }
            },
            {
                label: "Is the solution presently active in India / Overseas? If so, please provide details",
                key: "india_or_overseas",
                title: "india_or_overseas",
                type: "TEXTAREA",
                value: "",
                error: false,
                errorMessage: "Mandatory field*",
                errorLogic: (input) => {
                    if(validation.isEmpty(input)){
                        return true
                    }
                    return false;
                }
            },
            {
                label: "Does the applicant have any revenue-making track record?",
                key: "revenue_order",
                title: "revenue_order",
                type: "TEXT",
                value: "",
                error: false,
                errorMessage: "Mandatory field*",
                errorLogic: (input) => {
                    if(validation.isEmpty(input)){
                        return true
                    }
                    return false;
                }
            },
            {
                label: "Challenges faced so far in deploying the solution",
                key: "challenges",
                title: "challenges",
                type: "TEXTAREA",
                value: "",
                error: false,
                errorMessage: "Mandatory field*",
                errorLogic: (input) => {
                    if(validation.isEmpty(input)){
                        return true
                    }
                    return false;
                }
            },
            {
                label: "Motivation to join the sandbox",
                key: "motivation",
                title: "motivation",
                options: [
                    "Get Validated by Regulators",
                    "Market testing",
                    "Operate in a relaxed regulatory environment",
                    "Suggest and test new regulations"
                ],
                type: "OPTIONS",
                value: "Get Validated by Regulators",
                error: false,
                errorMessage: "Mandatory field*",
                errorLogic: (input) => {
                    if(validation.isEmpty(input)){
                        return true
                    }
                    return false;
                }
            },
            {
                label: "What is the preferred method of testing",
                key: "testing",
                title: "testing",
                options: ["Real Life Customers","Dummy Data"],
                type: "OPTIONS",
                value: "Real Life Customers",
                error: false,
                errorMessage: "Mandatory field*",
                errorLogic: (input) => {
                    if(validation.isEmpty(input)){
                        return true
                    }
                    return false;
                }
            },
            {
                label: "What activities does the applicant aim to undertake as part of the Sandbox?",
                key: "sandbox",
                title: "sandbox",
                type: "TEXTAREA",
                value: "",
                error: false,
                errorMessage: "Mandatory field*",
                errorLogic: (input) => {
                    if(validation.isEmpty(input)){
                        return true
                    }
                    return false;
                }
            },
            {
                label: "Does the applicant want to suggest any regulatory relaxations, if any",
                key: "regulatory_relaxations",
                title: "regulatory_relaxations",
                type: "TEXTAREA",
                value: "",
                error: false,
                errorMessage: "Mandatory field*",
                errorLogic: (input) => {
                    if(validation.isEmpty(input)){
                        return true
                    }
                    return false;
                }
            }
        ]
    }
];

export default questions;

export const draftMessageForSignature = (details) => {
    let a = details
        .map((sections) => {
            return sections.questions
                .map((question) => {
                    return `${question.label}: ${question.value}`;
                })
                .join("\n");
        })
        .join("\n\n");
    return `Intract asks you to sign this message for the purpose of verifying your account ownership and your form submission. This is READ-ONLY access and will NOT trigger any blockchain transactions or incur any fees 
    \n\n${a}`;
};
