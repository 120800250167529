import React from "react";
import Select from "../../../component/Forms/Select";


const Question = ({
    label,
    value,
    title,
    key,
    type,
    options,
    textAreaHeight,
    placeholder,
    error,
    handleInputChange,
    section,
    questionIdx
}) => {
    if (type === "OPTIONS") {
        return (
            <>
                <Select
                    options={options}
                    value={value}
                    setValue={(val) => {
                        handleInputChange(val, section, questionIdx);
                    }}
                    name={title}
                    label={label}
                />
            </>
        );
    } 
    else if (type === "TEXTAREA") {
        return (
            <>
                <div class="form-group mb-4">
                    <label class={`input-label required h5 `} for={key}>
                        {label}
                    </label>

                    <textarea
                        type={type}
                        class={`form-control form-control-hover-light ${error ? "is-invalid" : " border"}`}
                        id={key}
                        placeholder={placeholder || ""}
                        value={value}
                        name={title}
                        style={{
                            height: textAreaHeight ? textAreaHeight : "100px"
                        }}
                        onChange={(e) =>
                            handleInputChange(
                                e.target.value,
                                section,
                                questionIdx
                            )
                        }
                    />

                    {error && <div class="invalid-feedback">{error}</div>}
                </div>
            </>
        );
    }
    return (
        <>
            <div class="form-group mb-4">
                <label class={`input-label required h5`} for={key}>
                    {label}
                </label>

                <input
                    type={type}
                    class={`form-control form-control-hover-light ${error ? "is-invalid" : ""}`}
                    id={key}
                    value={value}
                    name={title}
                    onChange={(e) =>
                        handleInputChange(e.target.value, section, questionIdx)
                    }
                />

                {error && <div class="invalid-feedback">{error}</div>}
            </div>
        </>
    );
};

export default Question;
