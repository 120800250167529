import React from 'react'

const Contact = () => {
  return (
    <div>
        <footer class="container-lg text-center py-10">
            <ul class="list-inline mb-3">
                <li class="list-inline-item">
                    <a class="btn btn-soft-secondary btn-sm btn-icon rounded-circle" href="https://instagram.com/emerging.technology.ts.gov.in?igshid=Zjc2ZTc4Nzk=">
                        <i class="tio-instagram"></i>
                    </a>
                </li>
                <li class="list-inline-item">
                    <a class="btn btn-soft-secondary btn-sm btn-icon rounded-circle" href="https://twitter.com/EmergingTechTS">
                        <i class="tio-twitter"></i>
                    </a>
                </li>
                <li class="list-inline-item">
                    <a class="btn btn-soft-secondary btn-sm btn-icon rounded-circle" href="https://www.linkedin.com/company/et-itec-gots/">
                        <i class="tio-linkedin"></i>
                    </a>
                </li>
                <li class="list-inline-item">
                    <a class="btn btn-soft-secondary btn-sm btn-icon rounded-circle" href="https://discord.gg/7yyx3dMJp3/">
                        <i class="ai-discord"></i>
                    </a>
                </li>
            </ul>
            <p class="mb-0 mt-3">
                <h5>Contact:</h5>
                For further queries please contact: <br />
                Emerging Technologies Wing <br />
                ITE&C Department, Government of Telangana <br />
                Email: projectanalyst-itc@telangana.gov.in <br />
            </p>
            <img src = "/assets/logos/intract.png" className = "img-fluid mt-6" style = {{maxWidth: '60px'}}/>
            <a class='d-block mt-4 btn-link pointer' href = "https://intract.buzz">
                Powered by @Intract
            </a>
        </footer>
    </div>
  )
}

export default Contact