import React, {useEffect} from 'react'
import { Link } from 'react-router-dom';


const AuthLayout = ({children}) => {

    useEffect(() => {
        const ele = document.getElementsByTagName("BODY")[0];
        const ele1 = document.getElementById("main-content");
        ele.setAttribute('class', 'd-flex align-items-center min-h-100');
        ele1.setAttribute('class', 'container-fluid');
    }, [])

    return (
        <div class='container-fluid px-3'>
            <div class="row">
            <div class="col-lg-6 d-none d-lg-flex justify-content-center min-vh-lg-100 position-relative bg-light px-0 " >
                <div style = {{marginTop: '200px'}}>
                    <Logo />
                    <ValueProps />
                </div>
            </div>
            <div class="col-12 d-lg-none text-center mt-5" >
                <div >
                <Link to="/">
                    <img  src="/assets/img/emerging-technologies.png" alt="" style={{width: '15rem'}} />
                </Link>
                <hr />
                </div>
            </div>
            <div class="col-lg-6 d-flex justify-content-center align-items-center min-vh-lg-100">
                <div class="w-100 content-space-t-0 content-space-t-lg-2 content-space-b-1" style={{maxWidth: '35rem'}}>
                    {children}
                </div>
            </div>
            </div>
        </div>
    )
}

export default AuthLayout



const Logo = () => {
    return (
        <div>
        <div class="position-absolute top-0 start-0 end-0 mt-3 mx-3 ">
            <div class="d-none d-lg-flex justify-content-between">
                <Link to="/">
                    <img class="w-100" src="/assets/img/emerging-technologies.png" alt="" style={{minWidth: '7rem', maxWidth: '7rem'}} />
                </Link>
            </div>
        </div>
        
        </div>
    )
}



const ValueProps = () => {
    return(
        <div style={{maxWidth: '27rem'}}>
            <div class=" mb-5 ">
                <div class='text-center'>
                    <img class="w-100 mb-5" src="/assets/img/emerging-technologies.png" alt="" style={{minWidth: '100%', maxWidth: '20rem'}} />
                    <h2 class="display-4 mb-3">Please fill out the given details</h2>
                    <p class="">Applying to the regulatory sandbox by Telangana Govt. would entitle startups investors & corporates to the following benefits: </p>
                </div>
            </div>
            <ul class="list-checked list-checked-lg list-checked-primary list-py-2">
                <li class="list-checked-item">
                Official authorization on legal compliance, security and authentication from the government and regulators
                </li>
                <li class="list-checked-item">
                Mentorship to the participants to develop and improve their solutions
                </li>
                <li class="list-checked-item">
                Connects to Accelerators, Meetup events, Hackathons within the Telangana innovation ecosystem
                </li>
                <li class="list-checked-item">
                Easy market access and connection to VCs and angel investors
                </li>
            </ul>
            {/* <div class="mt-10 ">
                <div class="d-none d-lg-flex justify-content-between">
                    <Link to="/">
                        <img class="w-100" src="/assets/logos/intract.png" alt="" style={{minWidth: '3rem', maxWidth: '3rem'}} />
                    </Link>
                </div>
                <p>Powered by Intract</p>
            </div> */}
        </div>
    )
}


