import React from 'react'



const Timeline = () => {
  return (
    <>
    <div class='container-lg content-space-2 content-space-lg-3'>
        <div class="w-lg-75  mx-lg-auto">
            <h2 class="display-4 text-center mb-4">Participation in the Sandbox</h2>
            <ul class="lead text-start ps-3">
                <li>The sandbox will operate in a continuous format. </li>
                <li>The participants can exit the sandbox as and when they are through with their testing process. However, the testing period for a participant may not exceed 6 months. </li>
                <li>The application will be reviewed bu the governing council at each meeting after which the approved participants will be onboarded. </li>
                <li>Initially, we will keep the size of active participants in the Sandbox to about 10-15. This size may be increased later. </li>
                <li>The observations on regulatory policies will be passed on to the regulatory bodies and where ever necessary state-level policies will be drafted to implement the findings from the sandbox.</li>
            </ul> 
            <div class='row text-center'>
                <div class='col-12 col-md-3 mb-2 mb-lg-2'>
                    <div class='bg-dark rounded shadow card'>
                        <div class='card-body text-white'>
                            Application Stage
                        </div>
                    </div>
                </div>
                <div class='col-12 col-md-3 mb-2 mb-lg-2'>
                    <div class='bg-dark rounded shadow card'>
                        <div class='card-body text-white'>
                            Authorization Stage
                        </div>
                    </div>
                </div>
                <div class='col-12 col-md-3 mb-2 mb-lg-2'>
                    <div class='bg-dark rounded shadow card'>
                        <div class='card-body text-white'>
                            Testing Stage
                        </div>
                    </div>
                </div>
                <div class='col-12 col-md-3 mb-2 mb-lg-2'>
                    <div class='bg-dark rounded shadow card'>
                        <div class='card-body text-white'>
                            Exit Stage
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div>
    </div>
    <div class='container-lg '>
        <div class="w-lg-75 text-center mx-lg-auto mb-7 mb-md-10">
            <h2 class="display-4">Benefits to the Sandbox participants:</h2>
            <p className='lead'>For a detailed look on the Web 3 Regulatory Sandbox, please refer: <a href = "https://intract-dashboard-files.s3.ap-south-1.amazonaws.com/telangana-sandbox/Web3.0+Sandbox+Concept+Note.pdf" target="_blank" rel="noopener noreferrer">Concept Note</a></p>
        </div>
        <div class='row justify-content-center'>
            <div class='col-12 col-lg-10'>
                <div class='row'>
                    <div class="col-md-7 mb-4">
                        <div class="card card-lg h-100 bg-light border-0 shadow-none overflow-hidden">
                            <div class="card-body">
                                <i class='tio-files display-4 mb-4'></i>
                                <p class="card-text lead mt-2">Official authorization on legal compliance, security and authentication from the government and regulators on the solution offering improved credibility to sandbox participants</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-5 mb-4">
                        <div class="card card-lg h-100 bg-light border-0 shadow-none overflow-hidden">
                            <div class="card-body">
                                <i class='tio-group-senior display-4 mb-3'></i>
                                <p class="card-text lead mt-2">Mentorship to the participants to develop and improve their solutions</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-7 mb-4">
                        <div class="card card-lg h-100 bg-light border-0 shadow-none overflow-hidden">
                            <div class="card-body">
                                <i class='tio-route-dashed display-4 mb-3'></i>
                                <p class="card-text lead mt-2">Successful participants on exiting the sandbox will be connected to Accelerators, Meetup events, Hackathons within the Telangana innovation ecosystem</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-5 mb-4">
                        <div class="card card-lg h-100 bg-light border-0 shadow-none overflow-hidden">
                            <div class="card-body">
                                <i class='tio-credit-cards display-4 mb-3'></i>
                                <p class="card-text lead mt-2">Easy market access and connection to VCs and angel investors</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

      

    </div>
    </>
  )
}

export default Timeline