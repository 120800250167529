import React from 'react'

const About = () => {
  return (
    <div class='row align-items-center'>
        <div class="col-12 col-lg-10 container-sm">
            <div class="bg-light border rounded bg-light content-space-2 rounded-3 px-5 pt-4 px-6 pt-sm-10 px-sm-10">
                <div class="text-center mx-lg-auto">
                    <h2 class="display-4 ">About</h2>
                    <p class="lead mt-4">
                        Web 3.0 technologies, which make up the 3rd generation of the Internet, have rapidly scaled up over the last few years. The ecosystem now boasts thousands of startups and innovators worldwide. There exists a need for safe spaces where regulations can be tested before rolling out to the wider public. Therefore, as the latest initiative to encourage the Web 3 ecosystem in the country, the Government of Telangana has taken this initiative to test innovative Web 3 solutions. The sandbox will act as a safe space where firms can test their products for a specified period under a controlled environment. The sandbox will also bring together several stakeholders of the Web 3.0 community under a single umbrella to collaborate and innovate.
                        {/* The internet, after working in a centralized ecosystem for years, is now on edge to experience a breakthrough with the help of blockchain and its decentralized nodes. Web 3.0 technology gives rights back to its users, focusing on peer-to-peer interaction, and pushing them away from having to interact with centralized bodies. The move to Web 3.0 represents the next step in the internet ecosystem. <br /><br /> */}
                        {/* Regulators worldwide are trying to access these upcoming technologies and use them in different sectors for various applications to understand their possible impact on consumers and their adaptability to the current regulatory environment. As a result, there exists a need for safe spaces where regulations can be tested before rolling out to the wider public.<br /><br /> */}
                        {/* Therefore, as the latest initiative to encourage the Web 3 ecosystem in Telangana, Government of Telangana is launching a Regulatory Sandbox to test innovative Web 3 solutions. The sandbox will act as a safe space where firms can test their products for a specified period under a controlled environment and then post successful testing can roll their products out to the market. The sandbox will also bring together the different members of the Web3.0 community under a single umbrella to collaborate and innovate. */}
                    </p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default About