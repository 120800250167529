import React from 'react'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'

const SelectField = ({
    options, 
    value, 
    setValue, 
    error, 
    setError, 
    errorText, 
    name, 
    label,
    tooltip,
    size,
    required,
    light,
    labelClass,
}) => {
    return (
        <div class='form-group mb-5'>
            {label && <label class={`input-label h5 ${required ? 'required' : ''} ${labelClass}`}>
                {label}
                {tooltip && <OverlayTrigger overlay = {<Tooltip>{tooltip}</Tooltip>}>
                    <i class="tio-info ms-2 fs-7" ></i>
                </OverlayTrigger>}
            </label>}
            <select 
                class={`form-select form-select-hover-light text-dark border border ${error ? 'is-invalid' : ''}`} 
                value = {value}
                onChange = {e => {
                    setValue(e.target.value)
                }}
                autocomplete="off" 
            >
                {options.map(item => {
                    return item.label ? <option class='' value={item.value}>{item.label}</option> : <option value={item}>{item}</option>
                })}
            </select>
            {errorText && <div class = 'invalid-feedback'>{errorText}</div>}
        </div>
    )
}

export default SelectField




export const SelectFieldRow = ({icon, label, value, name, error, options, setValue, setError, placeholder, errorText, marginBotton, columnSize}) => {
    return (
        <div class={`row form-group mb-${marginBotton}`}>
            <div class={`col-sm-${columnSize} mb-2 mb-sm-0`}>
            <div class="media align-items-center mt-2">
                {icon && <i class={`${icon} nav-icon`}></i>}
                <div class="media-body input-label">{label}</div>
            </div>
            </div>
            <div class="col-sm">
            <SelectField 
                name = {name}
                value = {value}
                error = {error}
                setValue = {setValue}
                setError = {setError}
                errorText = {errorText}
                options = {options}
            />
            </div>
        </div>
    )
}