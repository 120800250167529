import React from 'react'

const Hero = () => {
  return (
    <div class="overflow-hidden gradient-radial-sm-primary">
      <div class="container-lg content-space-t-3 content-space-t-lg-3 content-space-b-2">
        <div class="w-lg-75 text-center mx-lg-auto text-center mx-auto">
          <div class="mb-7 animated fadeInUp">
            <img src = "/assets/logos/web3.png" style = {{maxWidth: '300px'}} class='img-fluid'/>
            <h1 class="display-2 mb-3">Regulatory Sandbox to test <span class="text-primary text-highlight-warning">innovative Web 3.0 solutions!</span></h1>
            {/* <p class="fs-2">The sandbox will act as a safe space where firms can test their products for a specified period under a controlled environment and then post successful testing can roll their products out to the market.</p> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Hero