import React from 'react'
import {Link} from 'react-router-dom'

const Header = () => {
  return (
    <header id="header" class="navbar navbar-expand-lg navbar-center navbar-light bg-white navbar-absolute-top navbar-show-hide navbar-scrolled" >
        <div class="container-lg">
            <nav class="js-mega-menu navbar-nav-wrap">
                <div class="navbar-brand" >
                    <img class="navbar-brand-logo" src="/assets/img/emerging-technologies.png" alt="Logo" data-hs-theme-appearance="default"  style = {{minWidth: '170px'}}/>
                </div>
                <div class="navbar-nav-wrap-secondary-content">
                    <Link to = "/form" class="btn btn-primary navbar-btn" href="">Apply to Sandbox</Link>
                </div>
                <div class="collapse navbar-collapse" id="navbarContainerNavDropdown">
                </div>
            </nav>
        </div>
    </header>
  )
}

export default Header