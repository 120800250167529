import mixpanel from 'mixpanel-browser';
import config from './config'

var isInitialized = false;

export const initAnalysis = () => {
    try{
        mixpanel.init(config.mixpanel, {
            api_host: "https://api.mixpanel.com",
            debug: true,
            ip: true,
            batch_size: 1,
            batch_flush_interval_ms: 0
        }); 
        console.log('Mixpanel initiated')
        isInitialized = true;
    } catch(err){
        console.log(err)
    }
};

export const logPageView = (user) => {
    try{
        if(window.gtag){
            window.gtag("event", "page_view", {
                page_path: window.location.pathname + window.location.search + window.location.hash,
                page_search: window.location.search,
            })
        }
        if(mixpanel.__loaded){
            mixpanel.track("PAGE_VIEW::DASHBOARD", {
                'page': window.location.pathname
            }); 
        }
    } catch(err){
        console.log(err)
    }
};

export const logEvent = (eventName, attributes) => {
    try{
        if(mixpanel.__loaded){
            console.log('Logging event: ', eventName)
            mixpanel.track(eventName, attributes);
        }
    } catch(err){
        console.log(err)
    }
};


export const logException = (description = '', fatal = false) => {
    if (description) {
        // ReactGA.exception({ description, fatal });
    }
};
