import React from 'react'
import { Link } from 'react-router-dom'


const Application = () => {
  return (
    <div>
        <div class="container-lg ">
            <div class="w-md-75 mx-md-auto">
                <hr class="my-7" />
                <div class="text-center">
                    <h3>If you are interested in applying to participate in the sandbox, <br />please share your details with us:</h3>
                    <Link className='btn btn-primary mt-3' to = "/form">Apply to participate in the sandbox here</Link> 
                </div>
            </div>
        </div>
    </div>
  )
}

export default Application