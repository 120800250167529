import React from 'react'

const Eligibility = () => {
  return (
    <div>
        <div class="overflow-hidden">
            <div class="container-lg content-space-t-2 content-space-t-lg-3">
                <div class="w-lg-75 text-center mx-lg-auto mb-4 mb-md-5">
                    <h2 class="display-4">Who can apply</h2>
                    <p class="lead">Whatever your status, our offers evolve according to your needs.</p>
                </div>
                <div class="w-md-50 mx-md-auto">
                    <div class="position-relative">
                        <div class="bg-dark rounded-2 p-5">
                            <div class="row align-items-sm-center">
                                <div class="col-12 col-md-3">
                                    <h3 class="text-white mb-1">Start-ups</h3>
                                </div>
                                <div class="col">
                                    <p class="text-white-70 mb-0">Any start-up registered in India can apply to be part of the sandbox provided they can prove expertise in the field for guidelines laid out with each cohort’s application.</p>
                                </div>
                            </div>
                            <hr class="bg-soft-light" />
                            <div class="row align-items-sm-center">
                                <div class="col-12 col-md-3">
                                    <h3 class="text-white mb-1">Innovators</h3>
                                </div>
                                <div class="col">
                                    <p class="text-white-70 mb-0">Any group of individuals that has not yet registered as a start-up but can sufficiently prove proficiency in the proposed sector as per the guidelines laid out with each cohort’s application.</p>
                                </div>
                            </div>
                            <hr class="bg-soft-light" />
                            <div class="row align-items-sm-center">
                                <div class="col-12 col-md-3">
                                    <h3 class="text-white mb-1">Corporates</h3>
                                </div>
                                <div class="col">
                                    <p class="text-white-70 mb-0">Organizations that are no longer start-ups but have sufficient experience in the field can apply to be part of the sandbox provided they prove their technical proficiency in the field as per the guidelines laid out with each cohort’s application.</p>
                                </div>
                            </div>
                        </div>
                        <div class="d-none d-md-block position-absolute bottom-0 start-0">
                            <img class="img-fluid" src="/assets/svg/oc-peeking.svg" alt="Image Description" style={{maxWidth: '8rem', marginLeft: '-7.8125rem'}} />
                        </div>
                        <div class="d-none d-md-block position-absolute top-50 end-0 translate-middle-y">
                            <img class="img-fluid" src="./assets/svg/oc-on-the-go.svg" alt="Image Description" style={{maxWidth: '15rem', marginRight: '-15rem'}} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Eligibility

